<template>
  <div class="my-4" style="min-height: 660px;">
    <div class="type-list flex flex-wrap items-center">
      <span class="mb-2">试卷类型：</span>
      <el-button type="warning" :plain="typeAction(item.val)" size="small" v-for="item of typeList" :key="item.val" class="mb-2" @click="selectType(item)">{{ item.name }}</el-button>
    </div>
    <div class="level-list flex flex-wrap items-center">
      <span class="mb-2">等级分类：</span>
      <el-button type="primary" :plain="levelAction(item.val)" size="small" v-for="item of levelList" :key="item.val" class="mb-2" @click="selectLevel(item)">{{ item.name }}</el-button>
    </div>
    <div class="status-list flex flex-wrap items-center">
      <span class="mb-2">试卷状态：</span>
      <el-button type="info"  size="small" :plain="statusAction(item.val)" v-for="item of statusList" :key="item.val" class="mb-2" @click="selectStatus(item)">{{ item.name }}</el-button>
    </div>
    <el-divider></el-divider>
    <div class="list-wrap text-sm" v-if="examList.length > 0">
      <el-card v-for="item of examList" :key="item.id" class="w-full mb-4 text-left" shadow="hover">
        <div class="flex flex-wrap">
          <div class="md:w-2/5 w-full pr-2">
            <p class="mb-4">{{ item.name }}</p>
            <p class="mb-4">完成时间：{{ item.update_time }}</p>
            <p class="mb-4">试卷状态：<el-tag :type="statusType(item.is_publish_answer)" size="medium">{{ statusName(item.is_publish_answer) }}</el-tag></p>
          </div>
          <div class="md:w-1/5 w-1/2">
            <p class="mb-2" v-if="typeName(item.type)">类型：{{ typeName(item.type) }}</p>
            <p class="mb-2" v-if="levelName(item.level)">等级：{{ levelName(item.level) }}</p>
            <p class="mb-2">题目数量：{{ item.question_num }}</p>
            <p class="mb-2">试卷总分：{{ item.score }}</p>
          </div>
          <div class="md:w-1/5 w-1/2">
            <p class="mb-4">正确题数：{{ item.correct_num }}</p>
            <p class="mb-4">最终得分：{{ item.final_score }}</p>
            <p class="mb-4">耗时：{{ item.finish_time }}</p>
          </div>
          <div class="md:w-1/5 w-full flex justify-end items-start">
            <el-button type="primary" size="small" v-if="item.is_publish_answer" @click="goAnalysis(item.id)">答案解析</el-button>
          </div>
        </div>
      </el-card>
      <el-pagination
        class="my-4 md:hidden"
        background
        small
        layout="prev, pager, next"
        :page-size="query.num"
        :total="examTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-pagination
        class="my-4 md:block hidden"
        background
        layout="prev, pager, next"
        :page-size="query.num"
        :total="examTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-empty v-else description="你还没有进行任何考试"></el-empty>
  </div>
</template>

<script>
import { record } from '@/api/exam'
export default {
  name: 'ExamRecord',
  data() {
    return {
      typeList: [
        { name: '全部', val: -1 },
        { name: 'Scratch', val: 0 },
        { name: 'C++', val: 1 },
        { name: 'Python', val: 2 },
        { name: 'NOIP', val: 3 },
        { name: '机器人', val: 4 },
      ],
      levelList: [
        { name: '全部', val: -1 },
        { name: '一级', val: 1 },
        { name: '二级', val: 2 },
        { name: '三级', val: 3 },
        { name: '四级', val: 4 }
      ],
      statusList: [
        { name: '全部', val: -1 },
        { name: '已审批', val: 1 },
        { name: '未审批', val: 0 }
      ],
      query: {
        type: -1,
        level: -1,
        status: -1,
        page: 1,
        num: 8
      },
      examList: [],
      examTotal: 0,
      currentPage: 1,
    }
  },
  computed: {
    filterType() {
      return this.typeList.filter(item => {
        return item.val !== -1
      })
    },
    filterLevel() {
      return this.levelList.filter(item => {
        return item.val !== -1
      })
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    typeAction(val) {
      if (this.query.type === val) {
        return !true
      }
      return !false
    },
    levelAction(val) {
      if (this.query.level === val) {
        return !true
      }
      return !false
    },
    statusAction(val) {
      if (this.query.status === val) {
        return !true
      }
      return !false
    },
    selectType(item) {
      this.query.type = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    selectLevel(item) {
      this.query.level = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    selectStatus(item) {
      this.query.status = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    typeName(val) {
      return this.typeList.find(item => {
        return item.val === val
      })?.name
    },
    levelName(val) {
      return this.levelList.find(item => {
        return item.val === val
      })?.name
    },
    statusName(val) {
      return this.statusList.find(item => {
        return item.val === val
      })?.name
    },
    statusType(val) {
      return val ? 'success' : 'warning'
    },
    init() {
      record(this.query).then(res => {
        this.examList = res.data.list
        this.examTotal = res.data.count
      })
    },
    goAnalysis(id) {
      const hrefData = this.$router.resolve({ path: '/exam/analysis', query: { id } })
      window.open(hrefData.href, 'analysis')
    }
  }
}
</script>