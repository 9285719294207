import request from '@/utils/request'
const baseURL = '/exam.center'

/**
 * 试卷列表
 * @param {*} params 
 * @returns 
 */
export function list(params) {
  return request({
    url: `${baseURL}/list`,
    method: 'get',
    params
  })
}
/**
 * 试卷详情
 * @param {*} params 
 * @returns 
 */
export function detail(params) {
  return request({
    url: `${baseURL}/detail`,
    method: 'get',
    params
  })
}
/**
 * 提交试卷
 * @param {*} data 
 * @returns 
 */
export function postExam(data) {
  return request({
    url: `${baseURL}/postExamByUser`,
    method: 'post',
    data
  })
}
/**
 * 考试记录
 * @param {*} params 
 * @returns 
 */
 export function record(params) {
  return request({
    url: `${baseURL}/record`,
    method: 'get',
    params
  })
}
/**
 * 试卷解析
 * @param {*} params 
 * @returns 
 */
 export function analysis(params) {
  return request({
    url: `${baseURL}/analysis`,
    method: 'get',
    params
  })
}